<template>
    <template-base>
        <div class="d-flex">
            <h1 class="align-self-center"> Lista de {{ title }}</h1>
            <div class="spacer"></div>
            <slot>
                <router-link :to="{ name: addRoute }" class="btn btn-success text-white align-self-center" :data-cy="`Adicionar ${name}`">Adicionar {{name}}</router-link>
            </slot>
        </div>
        <DataTable scroll :async="pagination" :loading="loading" :colunas="parsedCols" :linhas="linhas" :errMsg="errMsg" :state="dataTableState" @state-change="setStateDataTable">
            <template #results-page v-if="pagination">
                <select class="custom-select ml-1" :value="results" @input="changeResults" data-cy="resultados-pagina" >
                    <option value="10">10 por página</option>
                    <option value="20">20 por página</option>
                    <option value="50">50 por página</option>
                    <option value="100">100 por página</option>
                    <option v-if="canShowAllResults" value="-1">Mostrar todos</option>
                </select>
            </template>
            <template v-for="col in cols" v-slot:[col]="{value, item}">
                <slot :name="col" :value="value" :item="item">
                    <router-link
                        v-if="value && value.rota && value.nome && value.id"
                        :to="{ name: value.rota, params: { id: value.id }}"
                        title="Ir para a edição"
                    ><edit-icon size="16" /> {{value.nome}}</router-link>
                </slot>
            </template>
        </DataTable>
        <Paginator :page="page" :pages="pages" :count="count" :disabled="loading" :first="first" :last="last" @paginate="paginate" />
    </template-base>
</template>

<style scoped>
    .custom-select {
        width: auto;
    }
    .feather {
        margin-bottom: 4px;
    }
</style>

<script>
import DataTable from '@/components/DataTable'
import Paginator from '@/components/Paginator'
import TemplateBase from '@/templates/Base'
import axios from 'axios'

export default {
    name: 'GenericList',
    components: {
        DataTable,
        Paginator,
        TemplateBase,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        addRoute: {
            type: String,
            required: true,
        },
        editRoute: {
            type: String,
            required: true,
        },
        dataTableStateName: {
            type: String,
            required: true,
        },
        api: {
            // type: Function||String,
            required: true,
        },
        cols: {
            type: Array,
            required: true,
        },
        colsMap: {
            type: Function,
            required: true,
        },
        colsName: {
            type: Array,
            required: true,
        },
        canShowAllResults: {
            type: Boolean,
            default: false,
        },
        showInativos: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            lista: [],
            loading: false,
            errMsg: '',
            pagination: false,
            page: 0,
            pages: 1,
            results: 20,
            first: 0,
            last: 0,
            count: 0,
        };
    },
    computed: {
        dataTableState () {
            return this.$store.state.dataTables['dataTable' + this.dataTableStateName];
        },
        linhas () {
            return this.lista.map(row => ({
                href: { name: this.editRoute , params: { id: row.id } },
                cols: this.colsMap(row),
            }));
        },
        parsedCols () {
            return this.cols.map((c, idx) => this.colsName[idx] ? c : { value: c, sortable: false });
        }
    },
    methods: {
        setStateDataTable (...args) {
            this.$store.commit('dataTables/setStateDataTable' + this.dataTableStateName, ...args);
            this.page = 0;
            this.update(1, this.results);
        },
        changeResults (event) {
            this.page = 0;
            this.update(1, event.target.value);
        },
        paginate (p) {
            if (p === this.page) return;
            this.page = p;
            this.update(this.page, this.results);
        },
        refreshProps() {
            return // função serve para forçar o componente a atualizar os parâmetros antes de chamar um update
        },
        update (page = 1, results = 20) {
            this.loading = true;
            let url;
            if (typeof this.api === 'function') {
                this.pagination = true;
                url = this.api(page, results, this.dataTableState.query, this.colsName[this.dataTableState.sortBy], this.dataTableState.sortAsc, this.showInativos);
            } else {
                this.pagination = false;
                url = this.api;
            }
            return axios.get(url).then(res => {
                if (res.data.rows) { // paginação ativada
                    this.page = res.data.page;
                    this.pages = res.data.pages;
                    this.first = res.data.first;
                    this.last = res.data.last;
                    this.count = res.data.count;
                    this.results = res.data.results;
                }
                this.lista = res.data.rows || res.data;
                this.errMsg = '';
            }).catch(reason => {
                this.lista = [];
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
    },
}
</script>
