<template>
    <GenericList
        ref="lista"
        name="conversor"
        title="conversores"
        data-table-state-name="Conversor"
        add-route="add_conversor"
        edit-route="edit_conversor"
        :cols="['#', 'Nome', 'UUID', 'Tipo']"
        :cols-name="['id', 'nome', 'uuid', 'idTipoConversor']"
        :cols-map="i => [i.id, i.nome, i.uuid, i.tipoConversor && i.tipoConversor.nome]"
        :api="api"
    />
</template>

<script>
import GenericList from '@/components/GenericList'
import api from '@/api.js'

export default {
    components: {
        GenericList
    },
    data () {
        return {
            api: api.v1.conversor.list
        };
    },
    mounted () {
        this.$refs['lista'].update();
    }
}
</script>
