<template>
    <EditBase :loading="loading" :edicao="edicao" :nome="conversor.nome" placeholder="Novo conversor">
        <form @submit="formSalvar">
            <div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_nome">Nome *</label>
                        <input type="text" maxlength="120" required="required" :disabled="saving" class="form-control" id="i_nome" placeholder="Exemplo: A1-1" data-cy="Nome" v-model="conversor.nome">
                    </div>
                   <div class="form-group col-md-6">
                        <label for="i_uuid">UUID *</label>
                        <div class="input-group mb-3">
                            <input type="text" required="required" :disabled="saving || creatingUuid" class="form-control" id="i_uuid" data-cy="UUID" placeholder="Exemplo: 00000000-0000-0000-0000-000000000000" v-model="conversor.uuid">
                            <div class="input-group-append">
                                <button class="btn btn-secondary" @click="createUuid" :disabled="saving || creatingUuid" data-cy="Gerar" type="button">Gerar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                        <label for="i_tipo">Tipo *</label>
                        <multiselect id="i_tipo" :disabled="saving" v-model="conversor.tipo" :show-labels="false" :allow-empty="false" :searchable="true" :options="listaTiposConversores" label="nome" data-cy="Tipo" track-by="id"></multiselect>
                    </div>
                </div>
                <p class="small text-right">Campos marcados com * são obrigatórios.</p>
            </div>

            <div v-if="edicao" class="d-flex justify-content-between">
                <button class="btn btn-danger align-self-center lg" type="button" data-cy="Remover" @click="remover" :disabled="loading || saving || !edicao">Remover</button>
                <button class="btn btn-success align-self-center lg" type="submit" data-cy="Salvar"  :disabled="loading || saving || !isComplete">Salvar</button>
            </div>
             <div v-else class="d-flex justify-content-end">
                <button class="btn btn-success align-self-center lg" type="submit" data-cy="Salvar"  :disabled="loading || saving || !isComplete">Salvar</button>
            </div>
        </form>
    </EditBase>
</template>

<script>

function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

import { copyProps, TRY_TIMEOUT } from '@/helpers/common.js'
import axios from 'axios'
import api from '@/api.js'

import EditBase from '@/templates/EditBase'

export default {
    name: 'AddEditConversor',
    components: {
        EditBase,
    },
    data () {
        return {
            conversor: {
                id: null,
                nome: '',
                uuid: '',
                idTipoConversor: null,
                tipo: null
            },
            listaTiposConversores: [],
            errMsg: '',
            loading: true,
            edicao: false,
            saving: false,
            creatingUuid: false,
        }
    },
    computed: {
        isComplete () {
            let t=this.conversor;
            return !!(t.nome && t.uuid, t.tipo && typeof t.tipo.id !== 'undefined');
        },
    },
    methods: {
        updateListaTipos () {
            this.loading = true;
            return axios.get(api.v1.tipoConversor.list(1, -1)).then(res => {
                this.listaTiposConversores = res.data.rows;
            }).catch(reason => {
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
        createUuid () {
            this.creatingUuid = true;
            axios.get(api.v1.uuid).then(res => {
                this.conversor.uuid = res.data.uuid;
            }).catch(reason => {
                console.error("Impossível gerar UUID:", reason);
                this.$snotify.error('Não foi possível gerar o UUID.', 'Erro');
            }).then(() => {
                this.creatingUuid = false;
            });
        },
        getConversor () {
            let id = this.$route.params.id;
            this.edicao = !!id;
            this.loading = this.edicao; // se for adição loading=false;
            if (this.edicao) {
                axios.get(api.v1.conversor.get(id))
                .then(res => {
                    if (res.data == null) { // conversor não existe
                        this.$snotify.error('O conversor solicitado não existe.', 'Erro');
                        this.$router.replace('/conversores');
                    }
                    let conversor = copyProps(res.data)(['id', 'nome', 'uuid', 'idTipoConversor']);
                    this.updateListaTipos().then(() => {
                        conversor.tipo = this.listaTiposConversores.find(c => c.id === conversor.idTipoConversor);
                        this.conversor = conversor;
                        this.loading = false;
                    });
                })
                .catch(reason => {
                    console.trace(reason);
                    this.$snotify.warning('Não foi possível buscar o conversor, tentando novamente...', 'Erro');
                    setTimeout(() => this.getConversor(), TRY_TIMEOUT);
                })
            }
        },
        limparCampos() {
            this.conversor = {
                id: null,
                nome: '',
                uuid: '',
                idTipoConversor: null,
                tipo: null,
            };
        },
        salvar () {
            this.saving = true;
            this.$snotify.async('Aguarde...', 'Salvando', () => new Promise((resolve, reject) => {
                this.conversor.idTipoConversor = this.conversor.tipo && this.conversor.tipo.id;
                let payload = copyProps(this.conversor)(['id','nome', 'uuid', 'idTipoConversor']);
                let req = this.edicao ? axios.put(api.v1.conversor.update, payload)
                                      : axios.post(api.v1.conversor.create, payload);
                req.then(res => {
                    this.conversor = res.data;
                    this.saving = false;
                    if (!this.edicao) {
                        this.limparCampos();
                    } else {
                        this.$router.push({ name: 'conversores' });
                    }
                    resolve({
                        title: 'Sucesso!',
                        body: 'Conversor salvo',
                        config: options()
                    })
                }).catch(e => {
                    this.saving = false;
                    reject({
                        title: 'Erro ao salvar',
                        body: e.response.data ? e.response.data.error : e.toString(),
                        config: options(10000)
                    })
                });
            }));
        },
        formSalvar (e) {
            e.preventDefault();
            this.salvar();
            return false;
        },
        remover () {
            const doDelete = () => {
                return axios.delete(api.v1.conversor.destroy, { data: { id: this.conversor.id } })
                .then(res => {
                    return res.data.error ? Promise.reject(res.data.error) : res.data;
                })
                .catch(reason => {
                    if(reason.response && reason.response.data && reason.response.data.error) {
                        this.$swal.showValidationMessage('Falha ao excluir: ' + (reason.response.data.error));
                    }
                    else this.$swal.showValidationMessage('Falha ao excluir: ' + (reason ? reason.toString() : 'motivo desconhecido.'));
                })
            };

            // TODO: transformar em função auxiliar para evitar duplicação de código
            this.$swal.fire({
                title: 'Você tem certeza?',
                text: "A remoção de um conversor é irreversível",
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Remover',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !this.$swal.isLoading(),
                showLoaderOnConfirm: true,
                preConfirm: doDelete
            }).then(res => {
                if (res.value) {
                    this.$router.replace('/conversores');
                    this.$snotify.success('Conversor removido', 'Sucesso!');
                }
            })
        },
    },
    watch: {
        '$route': 'getConversor',
    },
    mounted () {
        this.getConversor();
        this.updateListaTipos();
    },
}
</script>
